import { render, staticRenderFns } from "./About.vue?vue&type=template&id=686c7031"
var script = {}
import style0 from "./About.vue?vue&type=style&index=0&id=686c7031&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports